#tablabusquedaform {
    max-width: 800px;
    min-width: 400px;
   
    min-height: 400px;
    width: 50%;
    height: 60%;
    margin: 100px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
  }
/*
  #busquedaform{
    width: 50%;
    height: 60%;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 25px;
  }*/


  .form-control{
    margin-bottom: 0.5rem;
  }

 /* .row  button {
    border-radius: 25px;
    width: 100%;
    height: 40px;
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
    background: rgb(34, 193, 195);
    background: linear-gradient(90deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    border: 0px;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
  }*/
  /*
  .bottontablabusqueda
  {
    width: 60%;
    height: 40px;
  }*/

  .inputbusqueda{
    
    display: inline-flex;
    align-items: left;
    padding-top: 2rem;
    max-width: 30%;
  }
  /*
  .bottonseguimiento{
    width: 100%;
    height: 40px;
    font-size: 1rem;
    
  }*/

  /* para tabla recomen */

  .responsiveTable {
    width: 100%;
  }
  
  .responsiveTable td .tdBefore {
    display: none;
  }
  
  @media screen and (max-width: 40em) {
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */
  
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }
  
    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      border-bottom: 2px solid #333;
    }
  
    .responsiveTable tbody tr {
      margin: .3em;
      border-radius: 10px;
      border: 1px solid #3a3838;
      padding: .40em;
      margin-bottom: 10px;
    }
  
  
    .responsiveTable td.pivoted {
      /* Behave like a "row" */
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      /*white-space: pre-wrap;*/
      padding: 0.0;
      overflow-wrap: break-word;
    }
  
    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;
  
      /* Top/left values mimic padding */
      left: 1rem;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: right !important;
      font-weight: 600;
    }

    .responsiveTable td .bottonseguimiento {
      margin: 5px;
      margin: 5px;
    }
  }
